// services/PreSortedOrder.js

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const submitOrderForm = async (data) => {
  const formData = new FormData();

  // Append all form fields except images
  for (let key in data) {
    if (key !== 'images') {
      formData.append(key, data[key]);
    }
  }

  // Append each image file individually
  if (data.images && data.images.length > 0) {
    data.images.forEach((file, index) => {
      formData.append(`images[${index}]`, file);
    });
  }

  try {
    const response = await axios.post(`${API_BASE_URL}/presorted-order/submit`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting order form:', error);
    throw error;
  }
};
