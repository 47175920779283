// src/services/api.js

import axios from 'axios';
import config from '../config/envConfig';

export const submitOrder = async (orderData) => {
  try {
    const response = await axios.post(`${config.API_URL}/api/orders/add-order`, orderData);
    console.log(response.orderData);
    console.log(response);

    console.log(response.data.data._id);

    return response.data;
  } catch (error) {
    if (error.response) {
      // // The request was made, but the server responded with a status code that falls out of the range of 2xx
      // console.error('Error response data:', error.response.data);
      // console.error('Error response status:', error.response.status);
      // console.error('Error response headers:', error.response.headers);
    } else if (error.request) {
      // The request was made, but no response was received
      // console.error('Error request:', error.request);
    } else {
      // Something else happened while setting up the request
      console.error('Error message:', error.message);
    }
    throw error;
  }
};
