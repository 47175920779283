import React, { useState } from "react";
import { Container, Button, Form, Row, Col } from "react-bootstrap";
import Images from "../../../../assets/Images";
import './PaymentMethod.css'
const PaymentMethod = () => {
  const [paymentMethods] = useState([
    { id: 1, image: Images.easypaisa, viewEdit: true },
    { id: 2,  image: Images.jazzcash, viewEdit: true },
    { id: 3, name: "Bank Transfer", image: null, viewEdit: true },
  ]);

  const [selectedMethod, setSelectedMethod] = useState(null);

  return (
    <Container className="mt-5">
      <h1 className="text-center mainhead pt-5 fw-bold" style={{ fontWeight: "bold", color: "#009999" }}>
        Payment Method
      </h1>
      <div  className="d-flex mt-5 justify-content-center justify-content-md-end align-items-start">
          <Button variant="info" className="paymentbtn">
            Add New Payment Method
          </Button>
        </div>
      <Row>
        <Col md={12}>
          <h3 className="fw-bold mt-4">Your Payment Method</h3>
          <Form>
            {paymentMethods.map((method) => (
              <div
                key={method.id}
                className="d-flex justify-content-between align-items-center mb-3"
              >
                <div className="d-flex align-items-center pt-4">
                  <Form.Check
                    type="radio"
                    id={`payment-${method.id}`}
                    name="payment-method"
                    checked={selectedMethod === method.id}
                    onChange={() => setSelectedMethod(method.id)}
                    className="me-2 "
                  />
                  {method.image ? (
                    <img
                      src={method.image}
                      alt={method.name}
                    
                      className=" w-25"
                    />
                  ) : null}
                  <span className="fs-4">{method.name}</span>
                </div>
                {/* View/Edit Option */}
                <div>
  <a
    href="#view-payment"
    className="fs-5 text-decoration-none me-3"
    style={{ color: "black", fontWeight: "500" }}
  >
    View /
  </a>
  <a
    href="#edit-payment"
    className="fs-5 text-decoration-none"
    style={{ color: "black", fontWeight: "500" }}
  >
    Edit
  </a>
</div>


              </div>
            ))}
          </Form>
        </Col>
        
      </Row>
    </Container>
  );
};

export default PaymentMethod;
