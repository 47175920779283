import React, { useRef, useState } from 'react';
import './Verification.css';
import logo from './../../assets/SmartMedicslogo.png';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { generateOtp, verifyOtp } from '../../services/generateOtp';
import LoadingSpinner from '../../Components/Spinner/LoadingSpinner';
import { useDispatch } from 'react-redux';
import { setEmail } from '../../features/email/emailSlice';
function Verification({ setIsOtpVerified }) {
  const navigation =useNavigate()
  const [mobile, setMobile] = useState('');
  const [email, setemail] = useState('');
  const [error, setError] = useState('');
  const[message,setmessage]=useState('');
  const [otp, setOtp] = useState('');
  const [regenratebtn,setregenratebtn]=useState(false);
  const [loader,setLoader]=useState(false);
  const[submitbtn,setsubmitbtn]=useState(false);
  const form = useRef();
  const enterOtp = useRef();
  const dispatch = useDispatch();
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleMobileChange = (e) => {
    const input = e.target.value;
    if (/^\d*$/.test(input)) {
      setMobile(input.slice(0, 11));
    }
  };

  const handleOtpChange = (e) => {
    const input = e.target.value;
    if (/^\d*$/.test(input)) {
      setOtp(input.slice(0, 6)); // Restrict OTP to 6 digits
    }
  };

  const otpvalidation = async (e) => {
    setsubmitbtn(false)
    setLoader(true);
  
    e.preventDefault();
    try {
      const response = await verifyOtp(email, otp); // Send email and OTP to verify
      // console.log("API Response:", response); // Log the response
      if (response.success) {
   
        setsubmitbtn(true)
        setLoader(false)
        setError('');
        // alert("OTP Verified Successfully");
        setmessage("OTP Verified Successfully")
        setIsOtpVerified(true); // Set OTP verification status to true
        navigation('/preSorted-order'); // Redirect to protected route
      } 
    } catch (error) {
      setsubmitbtn(true)

       setLoader(false)
      setmessage("")

      setError(error.response.data.error);

      if(error.response.data.error=="OTP has expired. Please request a new OTP."){
        setregenratebtn(true)
      }
      // console.error("Error in OTP verification:", error); // Log the error for debugging
    }
  };
  
  const showOtp = async (e) => {
    setLoader(true)

    e.preventDefault();
    if (!mobile && !email) {
      setError('Please fill in either your mobile number or email address.');
      setLoader(false)
      return;
    }
    if (mobile && mobile.length < 11) {
      setError('Please enter a valid mobile number.');
      return;
    }
    if (email && !validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
    if (mobile && email) {
      setError('Please fill in either your mobile number or email address.');
      setLoader(false)

      return;
    }
    setError('');
    form.current.className = "d-none";
    enterOtp.current.className = "d-block";
    try {
      const response = await generateOtp(email); // Call the separate API function with FormData
      if (response.success) {
    
         setLoader(false)

        // console.log(response);
        dispatch(setEmail(email)); // Save email to Redux store
        // localStorage.setItem("otpEmail", email);
        form.current.className = "d-none";
        enterOtp.current.className = "d-block";
        // alert('An OTP has been sent to your email.');
        setmessage("A 6 digit OTP has been sent to your email.")
        setsubmitbtn(true)
        setregenratebtn(false)
         
      }
    } catch (error) {
      setmessage('Failed to send OTP. Please try again.');
      setLoader(false)
      setregenratebtn(true) 
    }
  };

  return (  
    <>  
      <div className='gradient-background overflow-hidden'>
        <div className='row col-md-12 d-flex justify-content-center mt-5'>
          <img style={{ width: 150 }} src={logo} alt='logo' className='pt-5' />
        </div>

        <div className='d-flex flex-column justify-content-center text-center'>
          <h1 className='fs-2 pt-4 fw-bold'>
            <span>Step into the future of </span>
            <span>convenient healthcare today.</span>
          </h1>
        </div>

        {/* --------------------------FORM-------------------------------------- */}    
        {loader &&(<>
          <LoadingSpinner/>
        </>) } 
        <div className='d-flex justify-content-center d-block' ref={form}>
       
          <Form onSubmit={showOtp}>
            <div className='row mt-2'>
              <Form.Group controlId="formMobile">
                <p className='text-white pt-3 text-center'>Please enter your 11-digit mobile number</p>
                <Form.Control 
                  type="tel" 
                  inputMode="numeric" 
                  pattern="[0-9]*"
                  placeholder="Mobile Number" 
                  value={mobile} 
                  onChange={handleMobileChange}
                  className='w-sm-100 bg-transparent border-2 border-white textbox' 
                />
              </Form.Group>
            </div>

            <div className='row pt-3 fs-6 justify-content-center'>
              OR
              <p className='text-white text-center'>Please enter your Email Address</p>
            </div>
            <div className='row '>
              <Form.Group controlId="formEmail">
                <Form.Control 
                  type="email" 
                  placeholder="Email Address" 
                  value={email} 
                  onChange={(e) => setemail(e.target.value)}
                  className=' bg-transparent border-2 border-white textbox' 
                />
              </Form.Group>
            </div>
                
            {error && (
              <div className='error'>
              <p className="text-danger fs-6  text-center mt-2">{error}</p>

              </div>
            )}

            <Button variant="" type="submit" className='w-100 button mt-3'>
              Generate Pin
            </Button>
          </Form>
        </div>
{/* -------------------------------------------------------------- */}
        <div className='row d-none' ref={enterOtp}>
          <div className='d-flex justify-content-center'>
            <Form onSubmit={otpvalidation} className='pt-5'>
              <p className='text-white text-center'>{message}</p>
              <div className='row mt-1'>
                <Form.Group controlId="formOtp">
                  <Form.Control 
                    type="text" 
                    value={otp}
                    onChange={handleOtpChange} // Update OTP state on change
                    placeholder="OTP Number" 
                    required 
                    className='w-sm-100 bg-transparent border-2 border-white textbox' 
                  />
                </Form.Group>
              </div>
              {error && (
                <div className='error'>
                <p className="text-danger text-center mt-2">{error}</p>

                </div>
              )}
              <div className='justify-content-center d-flex ' >
              {regenratebtn && (
                <Button onClick={showOtp} className='button  bg-transparent' style={{color:"#074868"}}>Regenerate Otp</Button>
              )}
              </div>
           
                <Button  disabled={!submitbtn} variant="" type="submit" className='w-100 button mt-3 h-25'>
                Submit
              </Button>
          
              
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Verification;
