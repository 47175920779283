
import React from 'react';

const PrescriptionManagement = () => {
  return (
    <div>
      <h2>PrescriptionManagement</h2>
      <p></p>
    </div>
  );
};

export default PrescriptionManagement;
