import React, {useState, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'; 
import Footer from '../Components/Footer';
import CustomNavbar from '../Components/Navbar/CustomNavbar';

import { removeItem, increaseQuantity, decreaseQuantity, clearCart } from '../features/cart/cartSlice';
import { submitOrder } from '../services/RegularOrder'; // Import the API function

export default function CheckOut() {
  const navigate = useNavigate(); 
  

  // Load form data from localStorage
  const loadFormData = () => {
    try {
      const savedData = localStorage.getItem('checkoutFormData');
      return savedData ? JSON.parse(savedData) : {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        state: ''
      };
    } catch (error) {
      console.error("Failed to load form data from localStorage", error);
      return {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        state: ''
      };
    }
  };

  const cartItems = useSelector((state) => state.cart.items);
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(loadFormData);

  const { firstName, lastName, phone, email, address, city, state } = formData;

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
    localStorage.setItem('checkoutFormData', JSON.stringify(updatedFormData));
  }, [formData]);

  const handleRemoveItem = useCallback((id) => {
    dispatch(removeItem({ id }));
  }, [dispatch]);

  const handleIncreaseQuantity = useCallback((id) => {
    dispatch(increaseQuantity({ id }));
  }, [dispatch]);

  const handleDecreaseQuantity = useCallback((id) => {
    dispatch(decreaseQuantity({ id }));
  }, [dispatch]);
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Ensure all fields are filled
    if (!firstName || !lastName || !phone || !email || !address || !city || !state || cartItems.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please ensure that your cart is not empty and all details are filled out before placing the order.",
        footer: '<a href="/allproducts">Continue Shopping</a>',
        confirmButtonColor: "#00909D",
      });
      return;
    }
  
    // Ask for confirmation
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to place this order?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00909D',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, place order!',
      cancelButtonText: 'No, cancel'
    });
  
    // If user confirms, proceed with order submission
    if (confirmResult.isConfirmed) {
      // Prepare order data
      const orderData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        contact: phone, // Ensure 'contact' is mapped to 'phone'
        address: address,
        state: state,
        city: city,
        orderItems: cartItems.map((item) => ({
          product: item.id,
          quantity: item.quantity,
          price_pu: parseFloat(item.price.replace('Rs. ', '')),
          total_price: parseFloat(item.price.replace('Rs. ', '')) * item.quantity,
        })),
        totalOrderAmount: totalAmount,
      };
  
      console.log('Order Data:', orderData); // Check the output
  
      try {
        const response = await submitOrder(orderData);
        const orderId = response.data._id // Assuming the ID is located here
        Swal.fire({
          title: "Order Submitted Successfully!",
          icon: "success",
          iconColor: '#00909D',
          confirmButtonText: "OK",
          confirmButtonColor: "#00909D",
        }).then(() => {
          // Navigate to the OrderConfirmation page after the Swal is closed
          navigate('/order-confirmation', {
            state: {
              orderId: orderId,
              cartItems: cartItems,
              totalAmount: totalAmount,
              firstName: firstName,    // Include firstName
              lastName: lastName,      // Include lastName
              email: email,            // Include email
              phone: phone,            // Include phone
              address: address,        // Include address
              city: city,              // Include city
              state: state             // Include state
            }
          });
        });
        
        // Clear form and cart after successful submission
        setFormData({
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          address: '',
          city: '',
          state: ''
        });
        localStorage.removeItem('checkoutFormData');
        dispatch(clearCart());
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Submission Failed",
          text: "There was an issue submitting your order. Please try again later.",
        });
      }
    }
  };
  
  

  return (
    <>
      <CustomNavbar />
      <div className="justify-content-center">
        <Container>
          <Row className="justify-content-center mt-2">
            <Col className='me-1' xs={12} md={6} lg={6}>
              <h1 className="text-left">Check Out</h1>
              <h6 className="mt-2">Shipping Details</h6>
              <div className="mt-3">
                <Form onSubmit={handleSubmit}>
                  <Row className="font-weight-bold">
                    <Col xs={12} md={6}>
                      <Form.Group controlId="formFirstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control 
                          type="text" 
                          name="firstName"
                          placeholder="Enter your first name" 
                          value={firstName}
                          onChange={handleInputChange}
                          required 
                        />
                      </Form.Group>

                      <Form.Group controlId="formLastName" className="mt-3">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control 
                          type="text" 
                          name="lastName"
                          placeholder="Enter your last name" 
                          value={lastName}
                          onChange={handleInputChange}
                          required 
                        />
                      </Form.Group>

                      <Form.Group controlId="formPhone" className="mt-3">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control 
                          type="text" 
                          name="phone"
                          placeholder="Enter your phone number" 
                          value={phone}
                          maxLength={11}
                          onChange={handleInputChange}
                          required 
                        />
                      </Form.Group>

                      <Form.Group controlId="formEmail" className="mt-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control 
                          type="email" 
                          name="email"
                          placeholder="Enter your email" 
                          value={email}
                          onChange={handleInputChange}
                          required 
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="formAddress">
                        <Form.Label>Address</Form.Label>
                        <Form.Control 
                          as="textarea" 
                          name="address"
                          rows={3} 
                          placeholder="Enter your address" 
                          value={address}
                          onChange={handleInputChange}
                          required 
                        />
                      </Form.Group>

                      <Form.Group controlId="formCity" className="mt-3">
                        <Form.Label>City</Form.Label>
                        <Form.Control 
                          type="text" 
                          name="city"
                          placeholder="Enter your city" 
                          value={city}
                          onChange={handleInputChange}
                          required 
                        />
                      </Form.Group>

                      <Form.Group controlId="formState" className="mt-3">
                        <Form.Label>State</Form.Label>
                        <Form.Control 
                          type="text" 
                          name="state"
                          placeholder="Enter your state" 
                          value={state}
                          onChange={handleInputChange}
                          required 
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="d-none d-md-block">
                    <h6 className="mt-4">Payment Details</h6>
                    <Form.Group controlId="paymentMethod" className="mt-3">
                      <Form.Label>Payment Method</Form.Label>
                      <Form.Control style={{borderColor: "#00909D"}} as="select" required>
                        <option>Cash On Delivery</option>
                      </Form.Control>
                    </Form.Group>
                    <Button 
                      type="submit" 
                      className="mt-4 w-100"
                      style={{ backgroundColor: "#00909D", borderColor: "#00909D" }}
                    >
                      Place Order
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
            <Col xs={12} md={5} lg={4} className="mt-2 mt-md-0">
              <h6>Your Order</h6>
              {cartItems.length === 0 ? (
                <>
                  <p>Your cart is empty
                    <Link to={"/allproducts"} style={{ color: "#00909D" }} className='text-decoration-none'> Start Shopping</Link>
                  </p>
                </>
              ) : (
                <>
                  <div className="order-summary mt-3">
                    {cartItems.map((item) => (
                      <Row className="border-bottom py-2" key={item.id}>
                        <Col xs={8}>
                          <img src={item.img} alt={item.desc} className="w-25" />
                          {item.pname} <br/> {item.desc} <br/>
                          <Link style={{ color: "#00909D" }} className='text-decoration-none' onClick={() => handleRemoveItem(item.id)}>remove Item</Link>
                        </Col>
                        <Col xs={2} className='d-flex flex-row h-25  ' style={{ paddingLeft: "0" }}>
                          <button className='bg-white border-0' onClick={() => handleDecreaseQuantity(item.id)}>-</button>
                          <div>{item.quantity}</div>
                          <button className='bg-white border-0' onClick={() => handleIncreaseQuantity(item.id)}>+</button>
                        </Col>
                        <Col xs={2}>{`Rs. ${parseFloat(item.price.replace('Rs. ', '')) * item.quantity}`}</Col>
                      </Row>
                    ))}
                    <Row style={{ fontWeight: "bold", alignSelf: "center" }} className="py-2 bg-dark text-white text-center">
                      <Col xs={8}>Total |</Col>
                      <Col xs={4} className="text-right">RS : {totalAmount}</Col>
                    </Row>
                  </div>
                </>
              )}

              <div className="d-md-none mt-3">
                <h6 className="mt-4">Payment Details</h6>
                <Form.Group controlId="paymentMethod" className="mt-3">
                  <Form.Label>Payment Method</Form.Label>
                  <Form.Control as="select" required>
                    <option>Cash On Delivery</option>
                  </Form.Control>
                </Form.Group>
                <Button 
                  type="submit"
                   variant="primary"
                   onClick={handleSubmit} 
                  className="mt-4 w-100"
                  style={{ backgroundColor: "#00909D", borderColor: "#00909D" }}
                >
                  Place Order
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}
