import React, { useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { FaTachometerAlt, FaHome, FaUser, FaShoppingCart, FaFileMedical, FaBell, FaCreditCard, FaHeadset, FaSignOutAlt, FaUserCircle } from 'react-icons/fa';
import './Dashboard.css';
import logo from '../../../../assets/logo.png';
import { Link } from 'react-router-dom';
import NavbarDashboard from '../NavbarDashboard/Navbar';



const Dashboard = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <>
      <div className="dashboard-container">
        {/* Sidebar */}
        <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
          <div className="d-flex justify-content-center mt-3">
            <Link to={"/"}>
              <img style={{ width: 70 }} src={logo} alt="logo" />
            </Link>
          </div>

          {/* Edge-like Toggle Button */}
          <button className="toggle-btn" onClick={toggleSidebar}>
            {isCollapsed ? '➤' : '❮'}
          </button>

          <ul className="sidebar-list">
            <li className="mt-5">
              <NavLink to="customer-dashboard" className="sidebar-item">
                <FaTachometerAlt className="sidebar-icon fs-2 me-2" />
                {!isCollapsed && <span>Dashboard</span>}
              </NavLink>
            </li>
            <li className="pt-2">
              <NavLink to="order-management" className="sidebar-item">
                <FaShoppingCart className="sidebar-icon fs-2 me-2" />
                {!isCollapsed && <span>Order Management</span>}
              </NavLink>
            </li>
            <li className="pt-2">
              <NavLink to="prescription-management" className="sidebar-item">
                <FaFileMedical className="sidebar-icon fs-2 me-2" />
                {!isCollapsed && <span>Prescription Management</span>}
              </NavLink>
            </li>
            <li className="pt-2">
              <NavLink to="notifications" className="sidebar-item">
                <FaBell className="sidebar-icon fs-2 me-2" />
                {!isCollapsed && <span>Notifications</span>}
              </NavLink>
            </li>
            <li className="pt-2">
              <NavLink to="profile-managment" className="sidebar-item">
                <FaUser className="sidebar-icon fs-2 me-2" />
                {!isCollapsed && <span>Profile Management</span>}
              </NavLink>
            </li>
            <li className="pt-2">
              <NavLink to="address-book" className="sidebar-item">
                <FaHome className="sidebar-icon fs-2 me-2" />
                {!isCollapsed && <span>Address Book</span>}
              </NavLink>
            </li>
            <li>
              <NavLink to="payment-method" className="sidebar-item">
                <FaCreditCard className="sidebar-icon fs-2 me-2" />
                {!isCollapsed && <span>Payment Method</span>}
              </NavLink>
            </li>
            <li className="pt-2">
              <NavLink to="customer-support" className="sidebar-item">
                <FaHeadset className="sidebar-icon fs-2 me-2" />
                {!isCollapsed && <span>Customer Support</span>}
              </NavLink>
            </li>

            <li>
              <div className="d-flex justify-content-center">
                <hr className="w-75" />
              </div>
            </li>
            <li>
              <NavLink to="sign-out" className="sidebar-item">
                <FaUserCircle className="sidebar-icon fs-2 me-2" />
                {!isCollapsed && <span>Sign Out</span>}
              </NavLink>
            </li>
          </ul>
        </div>

        {/* Content */}
        <div className="content ">
          {/* Custom Navbar */}
          <NavbarDashboard/>
          {/* Main Content */}
          <div className="main-content">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
