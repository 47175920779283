import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../Components/Footer';
import vector from '../assets/Vector.png';
import CustomNavbar from '../Components/Navbar/CustomNavbar';

import images from '../assets/Images'
export default function Conditions() {
   

    return (
        <>
            <CustomNavbar />
            {/* PRIVACY POLICY-------------------------------- */}
      
            <Container fluid className="position-relative p-0 align-content-center">
                {/* Image Section */}
                <Row className="m-0">
                    <Col xs={12} className="p-0">
                        <Image
                            src={images.Conditions}
                            alt="Terms and Conditions"
                            fluid
                            style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                        />
                    </Col>
                </Row>

                {/* Text Section */}
                <Row
                    className="position-absolute start-0 ms-1 "
                    style={{ top: '20%' }}
                >
                    <Col xs={12} md={6}>
                        <div
                            style={{
                                color: "white",
                                borderLeft: "5px solid #00909D",
                                // paddingLeft: '15px',
                            }}
                            className="text-center text-md-start ps-2 mt-5 "
                        >
                            <h1
                                style={{
                                    lineHeight: '1.2',
                                    // fontSize: '2.5rem',
                                }}
                                className="display-4 fs-2"
                            >
                                Terms and <br /> Conditions
                            </h1>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* ---------------------------------------- */}
            <Container fluid className='mt-4  d-md-flex justify-content-md-center'>
                <Row className='m-4'>
                    <Col xs={12} className='p-4 pt-0 '>
                        <p className='fs-3'>
                            These Terms and Conditions ("Terms") govern your use of Smart Medics' website, mobile application, and services. By accessing or using our services, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please do not use our services.
                        </p>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='overflow-hidden ps-md-4'>  
            {/* Use of Services-------------------------- */}
            <Container fluid  className='' >
                <Row className='px-4'>
                    <Col xs={12}>
                        <h1 className='text-start' style={{ color: "#00909D" }}>Use of Services</h1>
                    </Col>
                </Row>
                {[
                    { title: "Medical Service", content: "Smart Medics provides online pharmacy services for ordering medications and healthcare products." },
                    { title: "Account Registration", content: "You may need to create an account to access certain features of our services. You agree to provide accurate and complete information during registration." },
                    { title: "Ordering and Payment", content: "You agree to pay for all orders placed through our website/mobile app and cash on delivery. Prices for medications and products are subject to change without notice." },
                    { title: "Delivery", content: "We aim to deliver orders promptly within the specified timeframe and to the delivery address provided. Delivery times may vary depending on availability and location." },
                    { title: "Intellectual Property", content: "The content on our website and mobile app, including text, graphics, logos, and images, is the property of Smart Medics and protected by copyright laws." },
                    { title: "Privacy", content: "Your use of our services is also governed by our Privacy Policy. By using our services, you consent to the collection, use, and sharing of your information as described in the Privacy Policy." },
                ].map((section, index) => (
                    <Row key={index} className='px-4'>
                        <Col xs={12} className='d-flex align-items-center py-3'>
                            <img src={vector} alt='img' className='me-2' />
                            <h5 className='fw-bold'>{section.title}</h5>
                        </Col>
                        <Col xs={12} className='px-4'>
                            <p>{section.content}</p>
                        </Col>
                    </Row>
                ))}
            </Container>
            {/* Limitation of Liability-------------------------- */}
            <Container fluid className='pt-4 '>
                <Row className='px-4'>
                    <Col xs={12}>
                        <h1 className='text-start' style={{ color: "#00909D" }}>Limitation of Liability</h1>
                    </Col>
                </Row>
                <Row className='px-4'>
                    <Col xs={12}>
                        <ol>
                            <li>
                                <h5 className='fw-bold'>Disclaimer:</h5>
                                <p>Smart Medics provides its services on an "as-is" and "as-available" basis. We make no warranties or representations about the accuracy or completeness of the information provided.</p>
                            </li>
                            <li>
                                <h5 className='fw-bold'>Limitation of Liability:</h5>
                                <p>In no event shall Smart Medics be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of our services.</p>
                            </li>
                        </ol>
                    </Col>
                </Row>
            </Container>
            {/* Termination */}
            <Container fluid className=''>
                <Row className='px-4 pt-2 '>
                    <Col xs={12}>
                        <h1 className='text-start' style={{ color: "#00909D" }}>Termination</h1>
                    </Col>
                </Row>
                <Row className='px-4'>
                    <Col xs={12}>
                        <ol>
                            <li>
                                <h5 className='fw-bold'>Termination:</h5>
                                <p>Smart Medics reserves the right to terminate or suspend your access to our services at any time, without prior notice, for any reason or no reason.</p>
                            </li>
                            <li>
                                <h5 className='fw-bold'>Effect of Termination:</h5>
                                <p>Upon termination, you will no longer have access to your account and any outstanding orders may be canceled.</p>
                            </li>
                        </ol>
                    </Col>
                </Row>
            </Container>
            {/* Changes of Terms */}
            <Container fluid className=''>
                <Row className='px-4 pt-2'>
                    <Col xs={12}>
                        <h1 className='text-start' style={{ color: "#00909D" }}>Changes of Terms</h1>
                    </Col>
                </Row>
                <Row className='px-4'>
                    <Col xs={12}>
                        <ol>
                            <li>
                                <h5 className='fw-bold'>Modification:</h5>
                                <p>Smart Medics reserves the right to modify these Terms at any time. We will notify you of any changes by posting the updated Terms on our website or mobile app.</p>
                            </li>
                            <li>
                                <h5 className='fw-bold'>Continued Use:</h5>
                                <p>Your continued use of our services after the posting of revised Terms constitutes your acceptance of the changes.</p>
                            </li>
                        </ol>
                    </Col>
                </Row>
            </Container>
            {/* Governing Law */}
            <Container fluid className=''>
                <Row className='px-4 pt-2'>
                    <Col xs={12}>
                        <h1 className='text-start' style={{ color: "#00909D" }}>Governing Law</h1>
                    </Col>
                </Row>
                <Row className='px-4'>
                    <Col xs={12}>
                        <ol>
                            <li>
                                <h5 className='fw-bold'>Jurisdiction:</h5>
                                <p>These Terms shall be governed by and construed in accordance with the laws of Pakistan.</p>
                            </li>
                        </ol>
                    </Col>
                </Row>
            </Container>
            {/* Contact Us */}
            <Container fluid className=''>
                <Row className='px-4 pt-2'>
                    <Col xs={12}>
                        <h1 className='text-start' style={{ color: "#00909D" }}>Contact Us</h1>
                        <p className='pt-2'>If you have any questions about this Privacy Policy or our practices, please contact us at:</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="d-flex flex-wrap align-items-center mb-2 ps-4">
                            <h5 className="me-2">Customer Support Email:</h5>
                            <h6 className="mb-0">contact@smartmedics.com.pk</h6>
                        </div>
                        <div className="d-flex flex-wrap align-items-center ps-4">
                            <h5 className="me-2">Customer Support Phone:</h5>
                            <h6 className="mb-0">123-456-789</h6>
                        </div>
                    </Col>
                </Row>

            </Container>
            </Container>
            <Footer />
        </>
    );
}
