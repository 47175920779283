// src/services/Products.js
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchProducts = async (page, limit, searchTerm = '') => {
  try {
    const response = await axios.get(`${API_BASE_URL}/products`, {
      params: {
        type: 'full',
        page,
        limit,
        search: searchTerm,
      },
    });

    if (response.data && response.data.data) {
      console.log(response);
      
      return {
        products: response.data.data.data.map((product) => ({
          id: product.id,
          name: product.title,
          desc: product.description,
          price: `Rs. ${product.price}`,
         thumbnail:  product.thumbnail
         ? `${API_BASE_URL.replace('/api', '')}/${product.thumbnail}`
         : 'default-thumbnail.jpg',// Fallback thumbnail image
          images: product.imageUrls || ['default-image.jpg'], // Use a fallback image if no imageUrls are provided
          specification: product.specification || 'No specification available',
          usageAndSafety: product.usageAndSafety || 'No usage information available',
          warnings: product.warnings || 'No warnings available',
          additionalInformation: product.additionalInformation || 'No additional info available',
          precautions: product.precautions || 'No precautions provided',
        })),
        totalPages: response.data.data.last_page,
      };
    }
    
    
    return { products: [], totalPages: 0 };
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};
