import React, { useState } from "react";
import { Table, Button, Form, Container } from "react-bootstrap";
import './AddressBook.css'
const AddressBook = () => {
  const [addresses, setAddresses] = useState([
    {
      id: 1,
      address: "House No. 1, Block A, North Karachi",
      cityCountry: "Karachi, Pakistan",
      phoneNumber: "+92 333 1234567",
      isEditing: false,
    },
  ]);

  const handleAddNew = () => {
    const newId = addresses.length + 1;
    setAddresses([
      ...addresses,
      {
        id: newId,
        address: "",
        cityCountry: "",
        phoneNumber: "",
        isEditing: true,
      },
    ]);
  };

  const handleInputChange = (e, id, field) => {
    const updatedAddresses = addresses.map((item) =>
      item.id === id ? { ...item, [field]: e.target.value } : item
    );
    setAddresses(updatedAddresses);
  };

  const handleDelete = (id) => {
    const filteredAddresses = addresses.filter((item) => item.id !== id);
    setAddresses(filteredAddresses);
  };

  const toggleEdit = (id) => {
    const updatedAddresses = addresses.map((item) =>
      item.id === id ? { ...item, isEditing: !item.isEditing } : item
    );
    setAddresses(updatedAddresses);
  };

  return (
   
    <Container className="mt-5">
         <div className="address-book-container">
         <h1 className='text-center mainhead pt-5 fw-bold '>Address Book</h1>
      <div className="d-flex justify-content-end pt-3">
          <Button  onClick={handleAddNew} variant="primary" type="submit" className='addnewbtn fw-bolder fs-5  '>
                Add New
                </Button>
      </div>
   
      <Table bordered responsive className="mt-3">
        <thead >
          <tr >
            <th>S#</th>
            <th>Address</th>
            <th>City / Country</th>
            <th>Phone Number</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {addresses.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>
                {item.isEditing ? (
                  <Form.Control
                    type="text"
                    placeholder="Enter Address"
                    value={item.address}
                    onChange={(e) => handleInputChange(e, item.id, "address")}
                  />
                ) : (
                  item.address
                )}
              </td>
              <td>
                {item.isEditing ? (
                  <Form.Control
                    type="text"
                    placeholder="Enter City / Country"
                    value={item.cityCountry}
                    onChange={(e) =>
                      handleInputChange(e, item.id, "cityCountry")
                    }
                  />
                ) : (
                  item.cityCountry
                )}
              </td>
              <td>
                {item.isEditing ? (
                  <Form.Control
                    type="text"
                    placeholder="Enter Phone Number"
                    value={item.phoneNumber}
                    onChange={(e) =>
                      handleInputChange(e, item.id, "phoneNumber")
                    }
                  />
                ) : (
                  item.phoneNumber
                )}
              </td>
              <td className="action">
                {item.isEditing ? (
                  <Button
                    variant="primary"
                    onClick={() => toggleEdit(item.id)}
                    className="btn-sm me-2 bg-white border-0 svbtn"
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    variant="secondary"
                    onClick={() => toggleEdit(item.id)}
                    className="btn-sm me-2 bg-white border-0 editbtn"
                  >
                    Edit
                  </Button>
                )}
                <Button
                  variant="danger"
                  onClick={() => handleDelete(item.id)}
                  className="btn-sm bg-white border-0 delbtn"
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
        </Container>
  );
};

export default AddressBook;
