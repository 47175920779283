
import React, { useState } from 'react';
import { Container,Form, Row, Col, Button } from 'react-bootstrap';
import './ProfileManagement.css';

const ProfileManagement = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    address: "",
    primaryEmail: "",
    secondaryEmail: "",
    primaryPhone: "",
    secondaryPhone: "",
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const validate = () => {
    const newErrors = {};

    if (!formData.fullName.trim()) {
      newErrors.fullName = "Full Name is required.";
    }

    if (!formData.address.trim()) {
      newErrors.address = "Address is required.";
    }

    if (!formData.primaryEmail || !/\S+@\S+\.\S+/.test(formData.primaryEmail)) {
      newErrors.primaryEmail = "Valid Primary Email is required.";
    }

    if (
      formData.secondaryEmail &&
      !/\S+@\S+\.\S+/.test(formData.secondaryEmail)
    ) {
      newErrors.secondaryEmail = "Valid Secondary Email is required.";
    }

    if (
      !formData.primaryPhone ||
      !/^\+92\s3[0-9]{2}\s[0-9]{7}$/.test(formData.primaryPhone)
    ) {
      newErrors.primaryPhone = "Valid Primary Phone Number is required.";
    }

    if (
      formData.secondaryPhone &&
      !/^\+92\s3[0-9]{2}\s[0-9]{7}$/.test(formData.secondaryPhone)
    ) {
      newErrors.secondaryPhone = "Valid Secondary Phone Number is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      alert("Profile submitted successfully!");
      // Handle form submission logic here
    }
  };

  return (
    <Container className='mt-5'>
        <h1 className='text-center mainhead pt-5 fw-bold '>Profile Management</h1>
        <Form onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={12} md={4}>
            Full Name
          </Form.Label>
          <Col sm={12} md={12}>
            <Form.Control
              type="text"
              placeholder="Your Name"
              name="fullName"
      className="custom-input-bg "
              value={formData.fullName}
              onChange={handleChange}
              isInvalid={!!errors.fullName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.fullName}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={12} md={12}>
            Address
          </Form.Label>
          <Col sm={12} md={12}>
            <Form.Control
              type="text"
              placeholder="Your Address"
              name="address"
              value={formData.address}
      className="custom-input-bg "

              onChange={handleChange}
              isInvalid={!!errors.address}
            />
            <Form.Control.Feedback type="invalid">
              {errors.address}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Primary Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email Address"
      className="custom-input-bg "

                name="primaryEmail"
                value={formData.primaryEmail}
                onChange={handleChange}
                isInvalid={!!errors.primaryEmail}
              />
              <Form.Control.Feedback type="invalid">
                {errors.primaryEmail}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Secondary Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email Address"
      className="custom-input-bg "

                name="secondaryEmail"
                value={formData.secondaryEmail}
                onChange={handleChange}
                isInvalid={!!errors.secondaryEmail}
              />
              <Form.Control.Feedback type="invalid">
                {errors.secondaryEmail}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Primary Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="+92 3XX XXXXXXX"
                name="primaryPhone"
      className="custom-input-bg "

                value={formData.primaryPhone}
                onChange={handleChange}
                isInvalid={!!errors.primaryPhone}
              />
              <Form.Control.Feedback type="invalid">
                {errors.primaryPhone}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Secondary Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="+92 3XX XXXXXXX"
                name="secondaryPhone"
                value={formData.secondaryPhone}
                onChange={handleChange}
      className="custom-input-bg "

                isInvalid={!!errors.secondaryPhone}
              />
              <Form.Control.Feedback type="invalid">
                {errors.secondaryPhone}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <div className="d-md-flex  justify-content-end">
        <Button variant="primary" className='cnclbtn fw-bolder fs-5  me-2  '>
           CANCEL
          </Button>
          <Button variant="primary" type="submit" className='savebtn fw-bolder fs-5  '>
            SAVE
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default ProfileManagement;
