import React, { useState } from 'react';
import { Table, Container, Form, Button } from 'react-bootstrap';
import './Notifications.css'
const Notifications = () => {
  // Dynamic Data
  const [notifications] = useState([
    { date: '01-12-2024', time: '05:20PM', description: 'Your order number 12345 is in progress.', link: '#' },
    { date: '15-11-2024', time: '13:20PM', description: 'Your order number 54321 has been shipped.', link: '#' },
    { date: '10-10-2024', time: '08:30PM', description: 'Your order has been approved.', link: '#' },
    { date: '12-9-2024', time: '04:15PM', description: 'Thank you very much for placing your order.', link: '#' },
  ]);

  // Toggle state for SMS and Email notifications
  const [smsEnabled, setSmsEnabled] = useState(true);
  const [emailEnabled, setEmailEnabled] = useState(false);

  return (
    <Container className='mt-5'>
              <h1 className='text-center mainhead pt-5 fw-bold '>Notifications</h1>
      {/* Notification Table */}
      <Table  bordered hover responsive className='mt-4 '> 
        <thead >
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th>Description</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody >
          {notifications.map((item, index) => (
            <tr key={index}>
              <td>{item.date}</td>
              <td>{item.time}</td>
              <td>{item.description}</td>
              <td >
                <a href={item.link} className='text-decoration-none text-black'>
                  View details
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Toggle Switches */}
      <div className="mt-4">
        <Form>
          <Form.Check
            type="switch"
            id="sms-notification-switch"
            label="Enable SMS notifications"
            checked={smsEnabled}
            onChange={() => setSmsEnabled(!smsEnabled)}
          />
          <Form.Check
            type="switch"
            id="email-notification-switch"
            label="Enable Email notifications"
            checked={emailEnabled}
          
            onChange={() => setEmailEnabled(!emailEnabled)}
          />
        </Form>
      </div>
    </Container>
  );
};

export default Notifications;
