
import React from 'react';

const OrderManagement = () => {
  return (
    <div>
      <h2>Order Management</h2>
      <p>Manage your orders here.</p>
    </div>
  );
};

export default OrderManagement;
