import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session'; // Use sessionStorage
import cartReducer from '../features/cart/cartSlice';
import emailReducer from '../features/email/emailSlice'; // Email slice
import { encryptTransform } from 'redux-persist-transform-encrypt';

// Config for persisting email state
const encryptor = encryptTransform({
  secretKey: process.env.REACT_APP_SECRET_KEY, // Use the secure key from .env file
  onError: (error) => {
    console.error('Encryption Error:', error);
  },
});
const emailPersistConfig = {
  key: 'Call1',
  storage: sessionStorage, // Use sessionStorage instead of localStorage
  transforms: [encryptor],
};
// Persisted email reducer
const persistedEmailReducer = persistReducer(emailPersistConfig, emailReducer);

const store = configureStore({
  reducer: {
    cart: cartReducer, // Cart state
    email: persistedEmailReducer, // Persisted email state
  },
});

const persistor = persistStore(store); // Create persistor for Redux Persist

// Export both store and persistor
export { store, persistor };

// Default export for store
export default store;
